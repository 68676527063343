import { useEffect, useState } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "@stardazed/streams-polyfill";

// Analytics
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga";
import "reflect-metadata";

// Services
import ConfigGlobalLoader from "services/config";
import Logger, { LogLevel } from "services/logger";

// Contexts
import { GlobalContextProvider } from "store/Global/provider";
import { HelmetProvider } from "react-helmet-async";
import IdentityKey from "utils/IdentityKey";

// App
import App from "app";

// Styles
import "inter-ui/inter.css";
import "main.css";
import "smartshare-ui/dist/index.css";

// Load global config
const configGlobal = ConfigGlobalLoader.config;

const Root = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);

    // Initialize Logger
    Logger.CreateLogger({
      sumoURL: "", //configGlobal.sumoURL, # TODO: ship elsewhere
      toConsole: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
      level: LogLevel.Info,
      sendIntervalMs: 20000,
      bufferSize: 1000
    });

    Logger.info("index.js", "App startup");

    // Initialize sentry for crash reports / exceptions
    if (configGlobal.sentryUrl && process.env.REACT_APP_DEVELOP !== "true") {
      Sentry.init({
        dsn: configGlobal.sentryUrl,
        environment: process.env.REACT_APP_ENV,
        release: configGlobal.version
      });
      Sentry.setUser({ key: IdentityKey.get() });

      // // https://docs.logrocket.com/docs/sentry
      // LogRocket.getSessionURL(sessionURL => {
      //   Sentry.configureScope(scope => {
      //     scope.setExtra("sessionURL", sessionURL);
      //   });
      // });
    }

    // Initialize Google Analytics
    ReactGA.initialize(configGlobal.googleAnalyticsID, {
      gaOptions: {
        clientId: IdentityKey.get()
      }
    });
    ReactGA.pageview(window.location.pathname + window.location.search);

    setIsLoading(false);

    return () => {
      // Cleanup
      Logger.info("index.js", "App shutdown");
    };
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Router forceRefresh={false}>
      <GlobalContextProvider>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </GlobalContextProvider>
    </Router>
  );
};

render(<Root />, document.getElementById("root"));
