import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import HomepageRoutes from "./HomepageRoutes";
import Header from "./components/Header";
import Footer from "./components/Footer/index";

const Homepage: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const location = useLocation();

  useEffect(() => {
    containerRef.current?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container ref={containerRef}>
      <Helmet>
        <title>AI Infrastructure for Developers</title>
        <link rel="canonical" href={`${window.location.origin}${location.pathname}`} />
        <meta
          name="description"
          content={
            "Run AI workloads anywhere with zero complexity. One line of Python, global GPUs, full control."
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`AI Infrastructure for Developers • Beam`} />
        <meta
          property="og:description"
          content={
            "Run AI workloads anywhere with zero complexity. One line of Python, global GPUs, full control."
          }
        />
        <meta property="og:image" content={"https://www.beam.cloud/meta-hero.png"} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:title" content={`AI Infrastructure for Developers • Beam`} />
        <meta
          name="twitter:description"
          content={
            "Run AI workloads anywhere with zero complexity. One line of Python, global GPUs, full control."
          }
        />
        <meta name="twitter:image" content={"https://www.beam.cloud/meta-hero.png"} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header />
      <Main>
        <HomepageRoutes />
      </Main>
      <Footer />
    </Container>
  );
};

export default Homepage;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.colors.gray1};

  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const Main = styled.main``;
