import { docsLink, slackLink, statusLink, githubLink } from "constants/Links";
import { ROUTES } from "routes/constants";

const NavItems = [
  {
    name: "Pricing",
    link: ROUTES.pricing
  },
  {
    name: "Blog",
    link: ROUTES.blog
  },
  {
    name: "Community",
    link: slackLink
  },
  {
    name: "Status",
    link: statusLink
  },
  {
    name: "About",
    link: ROUTES.about
  },
  {
    name: "Docs",
    link: docsLink
  }
];

export default NavItems;
